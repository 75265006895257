import React from 'react'
import './DoubleCircle.scss'

type Props = {}

const DoubleCircle = (props: Props) => {
  return (
    <div className='DoubleCircle'>
        <div className="outer-circle">
            <div className="inner-circle">

            </div>
        </div>
    </div>
  )
}

export default DoubleCircle